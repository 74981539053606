import React, { useEffect, useState } from 'react'
import { Link, Redirect } from 'react-router-dom';
import styles from '../scss/master.scss';

export default function ProjectModal(props) {
    const [content, setContent] = useState(null);
    const [redirect, setRedirect] = useState(false);

    useEffect(() => {
        if (!content) {
            fetch(`/api/projects?projectId=${props.projectId}`)
                .then(res => res.json())
                .then(json => {
                    if (json.ok) {
                        setContent(assembleContent(json.projectsInfo[0]));
                    } else {
                        setRedirect(true);
                    }
                })
                .catch(err => {console.log(err.message)});
        }
    }, [content]);

    const tagReducer = (list, tag, index, arr) => {
        list += tag;
        if (index < arr.length - 1) {
            list += ", ";
        }
        return list;
    };

    const assembleContent = (info) => {
        var carousel = info.gallery.images.map(imageName => {
            return <a href={info.gallery.url + imageName}><img src={info.gallery.url + imageName} alt={imageName} /></a>
        });
        var tagList = info.tags.reduce(tagReducer, '');

        return (
            <div className="project-modal">
                <div className="content-wrapper">
                    <div className="content">
                        <div className="title">
                            <h2>{info.title}</h2>
                            <div className='exit-link'><Link to="/projects" className="exit-link">X</Link></div>
                        </div>

                        <div className="section-heading">Description</div>
                        <p>{info.description}</p>

                        {info.result ? <>
                            <div className="section-heading">Result</div>
                            <p>{info.result}</p>
                        </> : null}

                        {info.gallery ? <>
                            <div className="section-heading">Gallery</div>
                            <div className="carousel">
                                {carousel}
                            </div>
                        </> : null}

                        {info.more ? <>
                            <div className="section-heading">More</div>
                            <div className="more">
                                {info.more.websiteUrl ? <a href={info.more.websiteUrl}>Website</a> : null}
                                {info.more.repoUrl ? <a href={info.more.repoUrl}>GitHub</a> : null}
                                {info.more.paperUrl ? <a href={info.more.paperUrl}>Paper</a> : null}
                            </div>
                        </> : null}

                        <div className="section-heading">Tags</div>
                        <p>{tagList}</p>
                    </div>
                </div>
            </div>
        )
    }

    return redirect ? <Redirect to="/projects"/> : content;
};