import React, { useEffect, useState } from 'react'
import styles from '../scss/master.scss';

export default function Contact() {

    return (
        <div className="page">
            <h1>Contact</h1>
            <div className="text-container">
                <p>
                    Please contact me for any questions, comments, or business inquiries.
                </p>
                <ul>
                    <li>E-mail: <a href="mailto:alexander.davidson98@gmail.com">alexander.davidson98@gmail.com</a></li>
                    <li><a href="https://www.linkedin.com/in/zander-davidson-18064a138/">LinkedIn</a></li>
                    <li><a href="https://github.com/Zander-Davidson">GitHub</a></li>
                </ul>
            </div>
        </div>
    );
};