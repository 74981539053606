import React, { useEffect, useState } from 'react';
import profilepic from '../static/profilepic.png';
import styles from '../scss/master.scss';

export default function Home() {
    // const [apiText, setApiText] = useState("");

    // useEffect(() => {
    //     fetch("/api/example/hello")
    //         .then(res => res.json())
    //         .then(json => setApiText(json.helloText.text))
    //         .catch(err => console.log(err.message));
    // });

    return (
        <div className="page">
            <h1>Hi, I'm Zander</h1>
            <img className="profilepic" src={profilepic} alt="profile pic"/>
            <div className="text-container">
                <p>
                    Software engineer, data enthusiast,
                    hobby mathematician. I'm making my mark on technology by doing the things I love.
                </p>
            </div>

            <div className="text-container">
                <h2>Software Engineer</h2>
                <p>
                    During the internships I worked while earning my BS in Computer Science,
                    I tackled many full-stack web development projects. Some of my goals included
                    becoming comfortable with essential skills such as database management with SQL,
                    coding back end logic with C#, and designing user experiences with
                    JavaScript/HTML.
                </p>
                <p>
                    I take pride and ownership in projects which drives me to constantly improve my skills,
                    and I love working with teams that share a similar mindset.
                </p>
            </div>

            <div className="text-container">
                <h2>Data Enthusiast</h2>
                <p>
                    I have always had a strong interest in database management, but my understanding
                    of data changed while developing a dashboard for monitoring customer interaction.
                    The data itself meant nothing without perspective and direction, so I began asking:
                </p>
                <ul>
                    <li>How can I extract meaningful knowledge from this data?</li>
                    <li>How can I use this knowledge to make a decision?</li>
                </ul>
                {/* <p>
                    The project introduced me to data science in the most organic way--by necessity.
                    Since then, however, I have continued to explore the power of data mining algorithms,
                    and necessity soon became facscination.
                </p> */}
                <p>
                    I continue to ask myself these questions in my daily life, observing that everything can
                    be simplified, automated, or understood using the knowledge we gain from data.
                </p>
            </div>

            <div class="text-container">
                <h2>Hobby Mathematician</h2>
                <p>
                    My love for mathematics long predates my work and education. Abstract algebra and graph
                    theory are among my favorite topics, and I find that keeping these ideas fresh in my mind
                    fosters a habitat of creativity when approaching problems in software development.
                </p>
                <p>
                    The most valuable thing I have learned in studying pure mathematics is how to step away
                    from a problem and reconsider my approach before proceeding. Mathematical proof is a gradual process of
                    breaking down a question into smaller questions, finding small answers,
                    then redefining the original question in light of new realizations and discoveries made along the way.
                </p>
                <p>
                    I apply this methodology to the problems I encounter on a daily basis in my work, keeping progress
                    on track towards effective, efficient solutions.
                </p>
            </div>
        </div>
    );
};