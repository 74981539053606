import React, { useEffect, useState } from 'react';
import { useQuery } from '../tools';
import ProjectCard from './ProjectCard';
import ProjectModal from './ProjectModal';
import styles from '../scss/master.scss';

export default function Projects() {
    const projectId = useQuery().get("projectId");
    const [projectCards, setProjectCards] = useState(null);

    useEffect(() => {
        if (!projectCards) {
            fetch("/api/projects")
                .then(res => res.json())
                .then(json => setProjectCards(
                    json.projectsInfo.map(info => {
                        return <ProjectCard key={info.id} info={info} />
                    })
                ))
                .catch(err => console.log(err.message));
        }
    }, [projectCards]);

    return !projectCards ?
        null
        :
        <div className="page">
            <div className="projects-wrapper" style={projectId ? {filter: "blur(12px)", transition: "0.1s"} : {}}>
                <h1>Projects</h1>
                <div className="text-container">
                    <p>Some of my favorite projects I've worked on recently</p>
                </div>
                {projectCards}
            </div>
            {projectId ? <ProjectModal projectId={projectId} /> : null}
        </div>
    ;
};