import React, { useEffect, useState }  from 'react'
import { NavLink, useLocation} from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import styles from '../scss/master.scss'

export default function Header() {
    var location = useLocation();

    const [showCollapse, setShowCollapse] = useState(false);
    const [isMobile, setIsMobile] = useState(false);
    const [collapseStyle, setCollapseStyle] = useState({visibility: "hidden"})

    const navLinks = [
        <NavLink className="nav-link" activeClassName="active-link" exact to="/" onClick={() => setShowCollapse(false)}> Home </NavLink>,
        <NavLink className="nav-link" activeClassName="active-link" to="/projects" onClick={() => setShowCollapse(false)}> Projects </NavLink>,
        <NavLink className="nav-link" activeClassName="active-link" to="/contact" onClick={() => setShowCollapse(false)}> Contact </NavLink>,
    ]

    useEffect(() => {
        setShowCollapse(false);
    }, [location]);

    useEffect(() => {
        let windowListener = () => {
            if (window.innerWidth < 550) setIsMobile(true);
            else setIsMobile(false);
        };
    
        window.addEventListener('resize', windowListener);
        return () => {
          window.removeEventListener('resize', windowListener);
        }
      }, []);

    const onCollapseClick = () => {
        if (collapseStyle != {}) setCollapseStyle({});
        setShowCollapse(!showCollapse);
    }

    return (
        <div>
            <div className='header'>
                <span className="nav-logo"> ZANDER DAVIDSON </span>
                {isMobile ? 
                    <button onClick={onCollapseClick}><FontAwesomeIcon icon={faBars} size="2x" style={{opacity: "0.5"}} /></button>
                    :
                    <span className="nav-links-wrapper">
                        {navLinks}
                    </span>
                }
            </div>

            {isMobile ? 
                <div style={collapseStyle} className={showCollapse ? "slide-out" : "slide-in"}>
                    {navLinks}
                </div>
                : null
            }
        </div>
    );
};