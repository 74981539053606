import React from 'react';
import { history } from "./helpers/history";
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import Header from './components/Header'
import Projects from './components/Projects';
import Home from './components/Home';
import Blog from './components/Blog';
import styles from './scss/master.scss';
import Contact from './components/Contact';

export default function App() {

  // standard react-router-dom site layout
  return (
    <div className="App">
      <Router history={history}>
        <Header />
        <Switch>
          <Route exact path='/' component={Home} />
          <Route path='/projects' component={Projects} />
          {/* <Route path='/blog' component={Blog} /> */}
          {/* <Route path='/about' component={About} /> */}
          <Route path='/contact' component={Contact} />
        </Switch>
      </Router>
    </div>
  );
}
