import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import ProjectModal from './ProjectModal';
import styles from '../scss/master.scss';

export default function ProjectCard(props) {
    return (
        <Link className="project-card" to={'/projects?projectId=' + props.info.projectId} style={{textDecoration: "none", color: "inherit"}}>

                <img src={props.info.gallery.url + props.info.gallery.thumbnail} alt={props.info.gallery.thumbnail} />
                <div>
                    <div className="info-bar">
                        <span>{props.info.category}</span>
                        <span>{props.info.year}</span>
                    </div>
                    <h2>{props.info.title}</h2>
                    <p>{props.info.preview}</p>
                </div>
        </Link>
    );
};